.page-content {
  background-color: #f8f9fa;
  padding: 50px 0;
}

.display-4 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #212529;
  margin-bottom: 20px;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
  color: #6c757d;
  margin-bottom: 20px;
}

.text-muted {
  color: #6c757d !important;
}

.benefits-list {
  font-size: 16px;
  line-height: 1.6;
  color: #212529;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.benefits-list li {
  margin-bottom: 10px;
}

.faq-item h6 {
  font-size: 16px;
  font-weight: 700;
}

.faq-item p {
  font-size: 14px;
  color: #6c757d;
}

button {
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 30px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
  color: #fff;
}

/* Adicionando regras para centralizar os cards */
.pricing-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 576px) {
  .pricing-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .pricing-col {
    flex: 0 0 calc(33.333% - 30px);
    max-width: calc(33.333% - 30px);
  }
}