.pricing-card {
  border: 0;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
  margin: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 280px;
  max-width: 350px;
  flex: 1 1 250px;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.pricing-card .card-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-title {
  font-size: 1.75rem;
  font-weight: 700;
  color: #212529;
}

.pricing-description {
  font-size: 1rem;
  font-weight: 400;
  color: #6c757d;
  margin-top: 10px;
}

.pricing-price {
  font-size: 2.3rem;
  font-weight: 700;
  color: #007bff;
}

.pricing-card p {
  font-size: 14px;
  color: #6c757d;
}

.pricing-card .icon {
  display: flex;
  align-items: center;
}

.pricing-card .btn {
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  padding: 10px 10px;
}

.plan-features p {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  color: #495057;
}

.plan-features i {
  margin-right: 10px;
  color: #007bff;
}

.pricing-col {
  display: flex;
  justify-content: center;
  flex: 1 1 350px;
  max-width: 350px;
}

@media (max-width: 768px) {
  .pricing-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}