@import "../../assets/scss/theme.scss";

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }

// adicione um .pb-<var> para adicionar padding bottom
.pb-[var] {
  padding-bottom: [var] !important;
}
// adicione um .pt-<var> para adicionar padding top
.pt-[var] {
  padding-top: [var] !important;
}
// adicione um .pl-<var> para adicionar padding left
.pl-[var] {
  padding-left: [var] !important;
}
// adicione um .pr-<var> para adicionar padding right
.pr-[var] {
  padding-right: [var] !important;
}
// adicione um .px-<var> para adicionar padding left e right
.px-[var] {
  padding-left: [var] !important;
  padding-right: [var] !important;
}
// adicione um .py-<var> para adicionar padding top e bottom
.py-[var] {
  padding-top: [var] !important;
  padding-bottom: [var] !important;
}
// adicione um .p-<var> para adicionar padding
.p-[var] {
  padding: [var] !important;
}
// adicione um .mb-<var> para adicionar margin bottom
.mb-[var] {
  margin-bottom: [var] !important;
}
// adicione um .mt-<var> para adicionar margin top
.mt-[var] {
  margin-top: [var] !important;
}
// adicione um .ml-<var> para adicionar margin left
.ml-[var] {
  margin-left: [var] !important;
}
// adicione um .mr-<var> para adicionar margin right
.mr-[var] {
  margin-right: [var] !important;
}
// adicione um .mx-<var> para adicionar margin left e right
.mx-[var] {
  margin-left: [var] !important;
  margin-right: [var] !important;
}
// adicione um .my-<var> para adicionar margin top e bottom
.my-[var] {
  margin-top: [var] !important;
  margin-bottom: [var] !important;
}
// adicione um .m-<var> para adicionar margin
.m-[var] {
  margin: [var] !important;
}
// adicione um .text-<var> para adicionar cor ao texto
.text-[var] {
  color: [var] !important;
}
// adicione um .bg-<var> para adicionar cor ao background
.bg-[var] {
  background-color: [var] !important;
}
// adicione um .border-<var> para adicionar cor a borda
.border-[var] {
  border-color: [var] !important;
}
// adicione um .border-<var>-<var> para adicionar cor a borda e tamanho
// adicione um .rounded para adicionar bordas arredondadas
// adicione um .rounded-<var> para adicionar bordas arredondadas com tamanho
// adicione um .shadow-<var> para adicionar sombra
// adicione um .text-<var> para adicionar cor ao texto


