.landing-footer {
  background-color: #f8f9fa;
  padding: 40px 0;
  border-top: 1px solid #e9ecef;
  text-align: center; /* Centraliza o texto */
}

.footer-logo {
  display: flex;
  justify-content: center; /* Centraliza a logo */
  margin-bottom: 20px;
}

.footer-logo-img {
  max-width: 150px; /* Ajuste o tamanho da logo conforme necessário */
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centraliza os itens no eixo horizontal */
}

.footer-column {
  margin-bottom: 20px;
  text-align: center; /* Centraliza o texto dentro das colunas */
}

.footer-list-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #212529;
  margin-bottom: 20px;
}

.footer-list-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-list-menu li {
  margin-bottom: 10px;
}

.footer-list-menu li a {
  color: #6c757d;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-list-menu li a:hover {
  color: #007bff;
}

.footer-border {
  border-color: #e9ecef;
}

.footer-text {
  font-size: 0.875rem;
  color: #6c757d;
  margin-top: 20px;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .footer-content {
    text-align: center; /* Centraliza o texto em telas menores */
  }

  .footer-column {
    margin-bottom: 20px;
    text-align: center; /* Centraliza o texto dentro das colunas */
  }
}