/* HeroSection.css */

.btn-custom-primary {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 30px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.btn-custom-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.btn-custom-secondary {
  background-color: #6c757d;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 30px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.btn-custom-secondary:hover {
  background-color: #5a6268;
  transform: scale(1.05);
}