.plan-container {
  background-color: #f8f9fa;
  padding: 2rem;
}

.plan-card {
  border: none;
  border-radius: 15px;
  padding: 2rem;
}

.plan-header {
  margin-bottom: 2rem;
}

.plan-type {
  font-size: 1.2rem;
  color: #6c757d;
}

.plan-title {
  font-size: 2rem;
  margin-top: 0.5rem;
}

.plan-price {
  font-size: 2rem;
  color: #007bff;
}

.plan-details .plan-form-group {
  margin-bottom: 1.5rem;
}

.plan-label {
  font-weight: bold;
  color: #495057;
  display: block;
  margin-bottom: 0.5rem;
}

.plan-input {
  border: 1px solid #ced4da;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.75rem;
  font-size: 1rem;
  text-align: center;
  width: 100%;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.plan-input:focus {
  border-color: #80bdff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.plan-button {
  margin-top: 1.5rem;
  border-radius: 50px;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.plan-button:hover {
  background-color: #0056b3;
}